var site = site || {};
site.Utilities = (function () {
    // Functions

    //gulp is breaking for some reason when including a reference to Math.ceil from 2 different JS files. I'm putting it in one spot until we can figure out why. 
    var mathCeil = function(val) {
        var newVal = Math.ceil(val);
        //console.log('mathCeil:', val, '->', newVal);
        return newVal;
    };

    var acceptCookieConsent = function($) {
        var cookieConsent = getCookie("cookieConsent");
        if(cookieConsent !== "accepted") {
            $('.js-cookie-consent').show();
            $('footer').addClass('mb-6');
            $('.js-accept-cookie-consent').on('click', function(e){
                e.preventDefault();
                $('.js-cookie-consent').hide();
                $('footer').removeClass('mb-6');
                document.cookie = "cookieConsent=accepted; path=/";
            })
        }

        //function getCookie        
        function getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1);
                if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
            }
            return "";
        }
    }
    
    
    const videoModals = () => {
        function getYoutubeId(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }
        // console.log('videoModals')
        const videoModalTriggers = document.querySelectorAll('.js-video-modal-trigger');
        const videoModalIframe = document.querySelector('.js-video-modal-iframe');
        const videoModalClose = document.querySelector('.js-video-modal-close');


        if (videoModalTriggers) {
            videoModalTriggers.forEach((trigger) => {
                trigger.addEventListener('click', () => {
                    const videoUrl = getYoutubeId(trigger.dataset.videoUrl);
                    setTimeout(() => {
                        videoModalIframe.src = 'https://www.youtube.com/embed/' + videoUrl + '?autoplay=1'
                    }, 100)
                    videoModalClose.addEventListener('click', () => {
                        videoModalIframe.src = '';
                    })
                })
                
            })
        }
    }

    const setLeftOffsetLg = (mediaQueries) => {
        const offsetElement = document.querySelectorAll('.js-left-offset');
        if (offsetElement && mediaQueries.lg.matches) {
            offsetElement.forEach(element => {
                const containerOffsetLeft = document.querySelector('.container').offsetLeft + "px";
                element.style.marginLeft = containerOffsetLeft;
                
            });
        } else {
            // const containerOffsetLeft = document.querySelector('.container').offsetLeft + 12 + "px";
            offsetElement.forEach(element => {
                element.style.marginLeft = 0;
            })

        }
    }
    
    // Return
    return {
        mathCeil : mathCeil,
        acceptCookieConsent : acceptCookieConsent,
        videoModals : videoModals,
        setLeftOffsetLg : setLeftOffsetLg
    };
}());